import { LoaderFunction } from "@remix-run/node";
import { MyError } from "~/components/AppShell/AppHeader/MyError";
import { NotFound } from "~/components/AppShell/NotFound";

export const loader: LoaderFunction = () => {
// throw new Response("Not Found", {
//     status: 404,
// });
  return <NotFound />;
};

export default function CatchAll() {
    return <NotFound />;
}